<template>
    <div class="w-full">
        <ConfirmationModal codeConfirmation @success="getAll" />

        <ModalEditHistory
            v-if="modalEditHistoryVisible"
            :modalEditHistory="modalEditHistory"
            :selectedHistoryProp="selectedHistory"
            @refresh="getAll"
            @close="modalEditHistory = false"
            @closed="modalEditHistoryVisible = false"
        />

        <ModalAddHistory
            v-if="modalAddHistoryVisible"
            :modalAddHistory="modalAddHistory"
            @refresh="getAll"
            @close="modalAddHistory = false"
            @closed="modalAddHistoryVisible = false"
        />

        <SummaryBar :historyData="historyData" />

        <div class="card main-card flex flex-col">
            <div class="flex justify-between">
                <div class="select">
                    <el-select v-model="selectedEmployee" :placeholder="$t('services.employees')" filterable clearable @change="selectEmployee">
                        <el-option-group :label="$t('services.active')">
                            <el-option
                                v-for="employee in activeEmployeesData"
                                :key="employee.uuid"
                                :label="employee.name + ' ' + employee.surname"
                                :value="employee.uuid"
                            />
                        </el-option-group>
                        <el-option-group :label="$t('services.archived')">
                            <el-option
                                v-for="employee in archivedEmployeesData"
                                :key="employee.uuid"
                                :label="employee.name + ' ' + employee.surname"
                                :value="employee.uuid"
                            />
                        </el-option-group>
                    </el-select>
                </div>
                <div class="menu flex">
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.add_to_history')" placement="top">
                        <el-button type="primary" size="mini" :disabled="!userCan('create service history')" @click="showAddHistoryModal">
                            <fa-icon :icon="['far', 'plus']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.export_to_pdf')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" @click="toPdf(false)">
                            <fa-icon :icon="['fas', 'file-pdf']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <el-tooltip :enterable="false" :openDelay="500" effect="dark" :content="$t('services.print')" placement="top">
                        <el-button type="primary" size="mini" :disabled="historyData.length == 0" class="btn-print" @click="toPdf(true)">
                            <fa-icon :icon="['fas', 'print']" fixed-width />
                        </el-button>
                    </el-tooltip>
                    <DateRangePicker @change="getAll" />
                </div>
            </div>
            <hr class="my-4">
            <el-table v-if="historyData.length > 0" :data="historyData" class="w-full">
                <el-table-column prop="status" width="50">
                    <template slot-scope="scope">
                        <div class="flex">
                            <el-tooltip v-if="workingStatus(scope.row)" :enterable="false" :openDelay="500" effect="dark" :content="$t('services.working')" placement="right">
                                <span class="w-3 h-3 bg-green-500 flex rounded-full" />
                            </el-tooltip>
                            <el-tooltip
                                v-if="shouldFinishStatus(scope.row) && userIsCleaningCompany()"
                                :enterable="false"
                                :openDelay="500"
                                effect="dark"
                                :content="$t('services.should_finish_already_20_min')"
                                placement="right"
                            >
                                <span class="w-3 h-3 bg-orange-400 flex rounded-full" />
                            </el-tooltip>
                            <el-tooltip
                                v-if="scope.row.status === 2 && userIsCleaningCompany()"
                                :enterable="false"
                                :openDelay="500"
                                effect="dark"
                                :content="$t('services.didnt_checked_out')"
                                placement="right"
                            >
                                <span class="w-3 h-3 bg-red-500 flex rounded-full" />
                            </el-tooltip>

                            <el-tooltip
                                v-if="userIsBuildingCompany()"
                                :enterable="false"
                                :openDelay="500"
                                class="item"
                                effect="dark"
                                placement="right"
                            >
                                <div slot="content">
                                    {{ $t('distance') }}: {{ scope.row.distance_in }}m<br>
                                    {{ $t('Accuracy') }}: {{ scope.row.accuracy_in }}m<br>
                                </div>
                                <span
                                    class="w-3 h-3  flex rounded-full"
                                    :class="{
                                        'bg-green-500': scope.row.alert_in === 0,
                                        'bg-red-500': scope.row.alert_in === 1,
                                    }"
                                />
                            </el-tooltip>
                            <el-tooltip
                                v-if="userIsBuildingCompany()"
                                :enterable="false"
                                :openDelay="500"
                                class="item"
                                effect="dark"
                                placement="right"
                                :disabled="scope.row.status === 2 || scope.row.status === 0"
                            >
                                <div slot="content">
                                    {{ $t('distance') }}: {{ scope.row.distance_out }}m<br>
                                    {{ $t('Accuracy') }}: {{ scope.row.accuracy_out }}m<br>
                                </div>
                                <span
                                    class="w-3 h-3 ml-1 flex rounded-full"
                                    :class="{
                                        'bg-gray-300': scope.row.status === 0,
                                        'bg-green-500': scope.row.status === 1 && scope.row.alert_out === 0,
                                        'bg-red-500': scope.row.status === 1 && scope.row.alert_out === 1,
                                        'bg-gray-800': scope.row.status === 2,
                                    }"
                                />
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('services.client')" prop="client" :show-overflow-tooltip="true" />
                <el-table-column :label="$t('services.address')" prop="address" :show-overflow-tooltip="true" />
                <el-table-column :label="$t('services.employee')" prop="employee" :show-overflow-tooltip="true" />
                <el-table-column :label="$t('services.duration')" prop="duration" width="100">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.duration) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('services.planned')" prop="planned" width="100">
                    <template slot-scope="scope">
                        {{ normalizeTime(scope.row.planned) }}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('services.rate')" width="170">
                    <template slot-scope="scope">
                        <div class="flex items-center">
                            <el-rate :value="scope.row.rate ? scope.row.rate.rate : 0" disabled />
                            <el-popover
                                v-if="scope.row.rate && scope.row.rate.comment"
                                placement="top-end"
                                :title="$t('clients.comment')"
                                width="300"
                                trigger="hover"
                                :content="scope.row.rate.comment"
                            >
                                <span slot="reference" class="flex h-5 w-5 justify-center items-center rounded-full border border-gray-600">
                                    <fa-icon :icon="['fas', 'info']" />
                                </span>
                            </el-popover>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('services.date')" prop="date" width="125" />
                <el-table-column width="75">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button
                                type="primary"
                                size="mini"
                                :disabled="!userCan('update service history')"
                                :title="$t('services.edit')"
                                @click="handleEdit(scope.row)"
                            >
                                <fa-icon :icon="['far', 'edit']" fixed-width />
                            </el-button>
                            <el-button
                                type="danger"
                                size="mini"
                                :disabled="!userCan('delete service history')"
                                :title="$t('services.delete')"
                                @click="handleDelete(scope.row.uuid)"
                            >
                                <fa-icon :icon="['fas', 'trash']" fixed-width />
                            </el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>

            <InitialLoader v-if="$wait.is('loading.initial_*')" />

            <NoDataInformation :data="noDataInformation" waitKey="loading" />
        </div>
    </div>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        DateRangePicker:  () => import(/* webpackChunkName: "DateRangePicker" */ '@/components/DateRangePicker'),
        ModalEditHistory: () => import(/* webpackChunkName: "ModalEditHistory" */ '@/views/services/components/ModalEditHistory'),
        ModalAddHistory:  () => import(/* webpackChunkName: "ModalAddHistory" */ '@/views/services/components/ModalAddHistory'),
        SummaryBar:       () => import(/* webpackChunkName: "EmployeesHistory/SummaryBar" */ './components/SummaryBar'),
    },

    data() {
        return {
            noDataInformation:       false,
            selectedEmployee:        null,
            historyData:             [],
            activeEmployeesData:     [],
            archivedEmployeesData:   [],
            modalEditHistoryVisible: false,
            modalEditHistory:        false,
            modalAddHistoryVisible:  false,
            modalAddHistory:         false,
            selectedHistory:         {},
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_history');
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('services.service_history'));
        this.$store.commit('setActiveMenuItem', '3-5');
        this.getAll();
        this.getActiveEmployees();
        this.getArchivedEmployees();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');
            const query = queryString.parse(window.location.search, { sort: false });

            this.selectedEmployee = query.employee;

            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history`, {
                    params: {
                        employee: query.employee,
                        from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                        to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    },
                });
                this.historyData = Object.freeze(data);
                this.noDataInformation = data.length;
                this.$wait.end('loading');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
                this.$wait.end('loading.initial_history');
            } catch (error) {
                this.$wait.end('loading');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
                this.$wait.end('loading.initial_history');
            }
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  `${process.env.VUE_APP_CORE_API_URL}/services/service_history/${uuid}`,
                actionType: 'destroy',
            });
        },

        async toPdf(toPrint = false) {
            const query = queryString.parse(window.location.search, { sort: false });

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/service_history_pdf_sign_route`, {
                params: {
                    employee: query.employee,
                    from:     query.from ? query.from : this.$dayjs().startOf('month').unix(),
                    to:       query.to ? query.to : this.$dayjs().endOf('month').unix(),
                    print:    toPrint,
                },
            });
            window.open(data);
        },

        async getActiveEmployees() {
            this.$wait.start('loading.active_employees');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees`);
            this.activeEmployeesData = employees.data;

            this.$wait.end('loading.active_employees');
        },

        async getArchivedEmployees() {
            this.$wait.start('loading.archived_employees');

            const employees = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/archived`);
            this.archivedEmployeesData = employees.data;

            this.$wait.end('loading.archived_employees');
        },

        selectEmployee() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.employee = this.selectedEmployee;

            this.$router.replace({
                query,
            }).catch(() => {});

            this.getAll();
        },

        handleEdit(history) {
            this.modalEditHistoryVisible = true;
            this.modalEditHistory = true;
            this.selectedHistory = history;
        },

        async showAddHistoryModal() {
            this.modalAddHistoryVisible = true;
            await new Promise(resolve => setTimeout(resolve, 100));
            this.modalAddHistory = true;
        },

        shouldFinishStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() > shouldFinishAt) {
                return true;
            }
            return false;
        },

        workingStatus(row) {
            const shouldFinishAt = this.$dayjs(row.date, 'HH:mm DD.MM.YYYY').add(row.planned + 20, 'minute');
            if (row.status === 0 && this.$dayjs() < shouldFinishAt) {
                return true;
            }
            return false;
        },
    },
};
</script>
<style scoped>
.btn-print {
    margin-right: 10px !important;
}

.menu .el-button--mini {
    padding: 7px !important;
}

.main-card {
    min-height: calc(100vh - 200px);
}

</style>
