<template>
    <div class="card card-f-h flex flex-col">
        <FailureCheckInTable :data="checkInData" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />
    </div>
</template>
<script>

export default {
    components: {
        FailureCheckInTable: () => import(/* webpackChunkName: "FailureCheckInTable" */ './FailureCheckInTable'),
    },

    data() {
        return {
            checkInData: [],
        };
    },


    created() {
        this.$store.commit('setPageTitle', `${this.$t('sidebar.failure_checkin')}`);
        this.$store.commit('setActiveMenuItem', '3-7');

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading');

            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/failure_checkin`);

            this.checkInData = data;

            this.$wait.end('loading');
        },
    },
};
</script>
